<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-12">
          <img
            src="@/assets/img/reg-banner-.png"
            class="img-fluid"
            alt="registration banner"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11 mx-auto">
          <p>
            For more information on PDP Week 2022:
            <a href="https://pdpc.gov.sg/pdpweek2021" target="_blank"
              >https://pdpc.gov.sg/pdpweek2022</a
            >
          </p>
          <div class="row">
            <div class="col-sm-2">
              <div class="form-group">
                <label>Salutation<span class="text-danger">*</span></label>
                <select
                  name="salutation"
                  class="form-control"
                  v-model="participant.salutation"
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr ">Dr</option>
                  <option value="Prof ">Prof</option>
                  <option value="Miss">Miss</option>
                  <option value="Mdm ">Mdm</option>
                </select>
                <span
                  class="text-danger"
                  v-for="message of validation_message.salutation"
                  :key="`salutation-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>First Name<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  class="form-control"
                  v-model="participant.first_name"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.first_name"
                  :key="`first_name-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Last Name<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  class="form-control"
                  v-model="participant.last_name"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.last_name"
                  :key="`last_name-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Email<span class="text-danger">*</span></label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Eg. john@gmail.com"
                  v-model="participant.email"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.email"
                  :key="`email-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="invisible">email</label>
                <input
                  type="email"
                  name="lname"
                  class="form-control"
                  placeholder="Reconfirm your email address"
                  v-model="participant.email_confirmation"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.email"
                  :key="`email-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Industry<span class="text-danger">*</span></label>
                <select
                  name="industry"
                  class="form-control"
                  v-model="participant.industry"
                >
                  <option value="Finance and Insurance">
                    Finance and Insurance
                  </option>
                  <option value="Professional, Scientific and Technical">
                    Professional, Scientific and Technical
                  </option>
                  <option value="Arts, Entertainment and Recreation">
                    Arts, Entertainment and Recreation
                  </option>
                  <option value="Accommodation and F&B">
                    Accommodation and F&B
                  </option>
                  <option value="Retail and Services">
                    Retail and Services
                  </option>
                  <option value="Transport and Storage">
                    Transport and Storage
                  </option>
                  <option value="Admin and Support Services">
                    Admin and Support Services
                  </option>
                  <option value="General (e.g. Chamber of Commerce)">
                    General (e.g. Chamber of Commerce)
                  </option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Information and Communications">
                    Information and Communications
                  </option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Education">Education</option>
                  <option value="Social Services">Social Services</option>
                  <option value="Construction">Construction</option>
                  <option value="others">Others</option>
                </select>
                <span
                  class="text-danger"
                  v-for="message of validation_message.industry"
                  :key="`industry-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Occupation<span class="text-danger">*</span></label>
                <select
                  name="occupation"
                  class="form-control"
                  v-model="participant.occupation"
                >
                  <option value="Senior Management (C-Level/Director)">
                    Senior Management (C-Level/Director)
                  </option>
                  <option value="Managerial/Supervisor">
                    Managerial/Supervisor
                  </option>
                  <option value="Executive/Technical">
                    Executive/Technical
                  </option>
                </select>
                <span
                  class="text-danger"
                  v-for="message of validation_message.occupation"
                  :key="`occupation-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
            <div class="col-sm-6" v-if="participant.industry == 'others'">
              <div class="form-group">
                <label>Other Industry<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="industry_other"
                  id="industry_other"
                  v-model="participant.industry_others"
                  class="form-control"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.industry_others"
                  :key="`industry_others-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Designation<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="dname"
                  class="form-control"
                  v-model="participant.designation"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.designation"
                  :key="`designation-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label
                  >Organisation Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name="oname"
                  class="form-control"
                  v-model="participant.organization_name"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.organization_name"
                  :key="`organization_name-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label
                  >Is your organisation a SME?<span class="text-danger"
                    >*</span
                  ></label
                >
                <select
                  name="sme"
                  class="form-control"
                  v-model="participant.organization_sme"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <span
                  class="text-danger"
                  v-for="message of validation_message.organization_sme"
                  :key="`organization_sme-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label>Phone<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="participant.phone"
                  class="form-control"
                />
                <span
                  class="text-danger"
                  v-for="message of validation_message.phone"
                  :key="`phone-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label
                  >Are you a Data Protection Officer (DPO)?<span
                    class="text-danger"
                    >*</span
                  ></label
                >
                <select
                  name="dpo"
                  class="form-control"
                  v-model="participant.dpo"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <span
                  class="text-danger"
                  v-for="message of validation_message.dpo"
                  :key="`dpo-${message}`"
                  >{{ message }}<br
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-11 mx-auto">
          <div class="sessions mt-4">
            <div class="session mb-5">
              <div
                class="card card-body p-2 px-4 bg-primary text-white text-uppercase b-600 mb-4"
              >
                Wednesday, 20 July 2022
              </div>
              <div class="row justify-content-between">
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    id="wed"
                    v-model="participant.pdp_seminar_session"
                  />
                  <label for="wed">2:30pm to 4:30pm</label>
                  <span class="text-danger"
                    >{{ capacity_message.pdp_limit }}<br
                  /></span>
                </div>
                <div class="col-sm-4">PDP Seminar</div>
                <div class="col-sm-2 b-700">Fee: Free</div>
              </div>
            </div>
            <div class="session mb-5">
              <div
                class="card card-body p-2 px-4 bg-primary text-white text-uppercase b-600 mb-4"
              >
                Thursday, 21 July 2022
              </div>
              <div class="row mb-4 justify-content-between">
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    id="thurs1"
                    v-model="participant.th_data_soverignty_session"
                  />

                  <label for="thurs1">9:30am to 11:30am</label>
                  <span class="text-danger"
                    >{{ capacity_message.th_data_soverignty_session }}<br
                  /></span>
                </div>
                <div class="col-sm-4">
                  Data Sovereignty, Data Transfers and Data Protection – Impact
                  on AI and Immersive Tech – By FPF and PDPC
                </div>
                <div class="col-sm-2 b-700">Fee: $30</div>
              </div>
              <div class="row justify-content-between">
                <div class="col-sm-3">
                  <input type="checkbox" id="thurs2" />
                  <label for="thurs2">2:30pm to 5:00pm</label>
                </div>
                <div class="col-sm-4">
                  The Road Ahead for Organisational Accountability: Effective
                  Implementation, Contextual Application and Regulatory
                  Expectation - By CIPL and PDPC
                </div>
                <div class="col-sm-2 b-700">
                  <!-- <div class="d-flex flex-column">
                    <span class="mb-3">Fee: $30</span>
                    <div class="mb-2">
                      <input
                        type="radio"
                        id="vir"
                        name="radio-btn"
                        value="selected"
                        v-model="virtual_session"
                      />

                      <label for="vir">Virtually</label>
                      <span class="text-danger"
                        >{{ capacity_message.virtual_roadahead_session }}<br
                      /></span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="phy"
                        name="radio-btn"
                        value="selected"
                        v-model="physical_session"
                      />

                      <label for="phy">Physically</label>
                      <span class="text-danger"
                        >{{ capacity_message.physical_roadahead_session }}<br
                      /></span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="session mb-5">
              <div
                class="card card-body p-2 px-4 bg-primary text-white text-uppercase b-600 mb-4"
              >
                Friday, 22 July 2022
              </div>
              <div class="row mb-4 justify-content-between">
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    id="fri1"
                    v-model="participant.fr_dpo_session"
                  />

                  <label for="fri1">9:30am to 12:00pm</label>
                  <span class="text-danger"
                    >{{ capacity_message.fr_dpo_session }}<br
                  /></span>
                </div>
                <div class="col-sm-4">
                  Data Protection Obligations: Global Edition – By AsiaDPO
                </div>
                <div class="col-sm-2 b-700">Fee: $30</div>
              </div>
              <div class="row justify-content-between">
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    id="fri2"
                    v-model="participant.fr_navigating_breach_session"
                  />

                  <label for="fri2">1:30pm to 4:30pm</label>
                  <span class="text-danger"
                    >{{ capacity_message.fr_navigating_breach_session }}<br
                  /></span>
                </div>
                <div class="col-sm-4">
                  Navigating The Data Breach Notification Obligation – By CSDPC,
                  LSS
                </div>
                <div class="col-sm-2 b-700">Fee: $30</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-11 mx-auto">
          <p>*Priority for in house DPOs</p>
          <p>
            Limited capacity for all tracks. PDPC and its partners reserve the
            rights on the acceptance and confirmation of registrants for the
            respective tracks. Successfully registrants will receive their
            confirmation email by xxx
          </p>
          <span class="text-danger">{{ selectanyone }}<br /></span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 mx-auto">
          <button
            class="btn btn-primary btn-lg btn-block text-uppercase b-600"
            @click="store()"
            v-if="!loading"
          >
            Submit
          </button>
          <button
            class="btn btn-primary reg-btn btn-lg btn-block b-700"
            disabled
            v-if="loading"
          >
            Please wait ...
          </button>
        </div>
      </div>
    </div>
    <!-- success modal -->
    <div
      class="modal fade"
      id="successModal"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img
                  src="@/assets/img/register/succesicon.png"
                  class="img-fluid"
                  alt="success icon"
                />
              </div>
            </div>
            <h1 class="mb-3">SUBMISSION SUCCESSFUL!</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import $ from "jquery";
export default {
  name: "register",
  data() {
    return {
      loading: false,
      participant: {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        pdp_seminar_session: 0,
        th_data_soverignty_session: 0,
        virtual_roadahead_session: 0,
        physical_roadahead_session: 0,

        fr_dpo_session: 0,
        fr_navigating_breach_session: 0,
        industry_others: "",
        phone: "",
      },
      validation_message: {
        frst_name: [],
        last_name: [],
        email: [],
        email_confirmation: [],
        salutation: [],
        industry: [],
        occupation: [],
        organization_name: [],
        designation: [],
        organization_sme: [],
        dpo: [],
        phone: [],
      },
      capacity_message: {
        pdp_limit: "",
        th_data_soverignty_session: "",
        virtual_roadahead_session: "",
        physical_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
      },
      selectanyone: "",
      virtual_session: "",
      physical_session: "",
    };
  },
  methods: {
    store() {
      this.loading = true;
      this.resetValidation();
      this.resetcapacitymessage();

      if (
        this.participant.pdp_seminar_session == 0 &&
        this.participant.th_data_soverignty_session == 0 &&
        this.virtual_session == 0 &&
        this.physical_session == 0 &&
        this.participant.fr_dpo_session == 0 &&
        this.participant.fr_navigating_breach_session == 0
      ) {
        this.selectanyone = "Please select any one session";
        this.loading = false;
        return;
      }

      if (this.virtual_session == "selected") {
        this.participant.virtual_roadahead_session = 1;
      }
      if (this.physical_session == "selected") {
        this.participant.physical_roadahead_session = 1;
      }

      Csrf.getCookie().then((res) => {
        Api.post("participant", this.participant).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;
            $(window).scrollTop(0);
            return;
          }

          if (res.data.capacity_error) {
            this.capacity_message = res.data.capacity_message;
            this.loading = false;

            return;
          }
          this.successModal();
          this.resetValidation();
          this.resetcapacitymessage();
        });
      });
    },
    successModal() {
      this.participant = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        pdp_seminar_session: 0,
        th_data_soverignty_session: 0,
        virtual_roadahead_session: 0,
        physical_roadahead_session: 0,

        fr_dpo_session: 0,
        roadahead_session: 0,
        fr_navigating_breach_session: 0,
        industry_others: "",
        phone: "",
      };
      this.loading = false;
      this.virtual_session = "";
      this.physical_session = "";

      $("#successModal").modal("show");
    },
    resetValidation() {
      this.validation_message = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        industry_others: "",
        phone: "",
      };
    },
    resetcapacitymessage() {
      this.capacity_message = {
        pdp_limit: "",
        th_data_soverignty_session: "",
        virtual_roadahead_session: "",
        physical_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
      };
    },
    // roadheadseesion() {
    //   this.participant.virtual_roadahead_session =
    //     !this.participant.virtual_roadahead_session;
    //   this.participant.physical_roadahead_session =
    //     !this.participant.physical_roadahead_session;
    // },
  },
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }
  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }
      label {
        font-weight: 700;
      }
    }
  }
}
</style>
